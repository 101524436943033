<template>
<div>
    <div class="login-div" style="background-size:cover; min-height:100vh">

        <div class="row"> 
            <div class="col-lg-12 col-12 " >
                <div style="background:#fff; padding:20px; max-width:500px; margin:0 auto; margin-top:50px;">
                <button style="background: rgb(242, 242, 242); border-radius: 5px; border: 1px solid rgb(242, 242, 242); padding: 5px; font-size: 12px;min-width:60px" @click="$router.go(-1)">
<i class="fa fa-chevron-left pr-2"></i> Back
</button>

                    <div style="margin:0 auto; min-width:100%;display: flex;
    justify-content: center;">

                 <img src="../../assets/images/logo.png" height="90" class="mt-4" @click="sendToMarket">

                </div>
                <div class="form-div " style="min-height:60vh">
                    <form action class="form">
                        <h4 class="mt-2 mb-4 head-font text-center">{{tran.change_pass}}</h4>

                        <div class="form-group mb-4">
                            <label class="form-label" for="first" @click.prevent="focusOnInput($event)">{{tran.old_pass}}</label>
                            <div class="eye-div">
                                <input
                    v-model="oldPassword"
                    class="form-input"
                    :type="hideInput"
                    autocomplete="off"
                  @keyup="checkEnterButton">
                                <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <label class="form-label" for="first" @click.prevent="focusOnInput($event)">{{tran.new_pass}}</label>
                            <div class="eye-div">
                                <input
                    v-model="newPassword"
                    class="form-input"
                    :type="hideInput"
                    autocomplete="off"
                  @keyup="checkEnterButton">

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="last">{{tran.retype_new_pass}}</label>
                                <input class="form-input" :type="hideInput" autocomplete="off" @keyup="checkEnterButton">
              </div>

                                <div class="form-group">
                                    <button
                  type="button"
                  class="submit-btn mt-2"
                  :class="{'fade-elem': loader}" style="background:#0094FF; border:1px solid #0094FF"
                  @click.prevent="changePassword">
                  {{ loader ? '' : tran.save }}
                  <span class="loader mt-1" v-if="loader"></span>
                </button>
                                </div>
                    </form>
                    <!-- <p class="text-center">Having issues with setting up your PIN? <span style="text-decoration:underline; cursor:pointer" @click="resetPin"> Click Here</span></p> -->
               
            </div>
            </div>
            <!-- <div class="col-lg-4 col-12 login-container text-center">
               
        </div> -->
                </div>
    </div>
</div>
</div>
</template>

<script>
import trans from "../../../translations";
import {
    mapState
} from "vuex";
import http from "../../http/index";
import api from "../../apis/index";
import {
    userNotification
} from "../dashboard/mixins/notifyUser.js";
import {
    setTimeout
} from 'timers';
export default {

    mixins: [userNotification],
    data() {
        return {

            tran: {},
            hideEye: false,
            showEye: true,
            hideInput: "password",
            oldPassword: '',
            newPassword: '',
            retypeNewPin: ''
        };
    },
    computed: {
        ...mapState({

            formdata: state => state.auth.updatePasswordData,

            loader: state => state.auth.loader,

            token: state => state.auth.token,

            userProfileInfo: state => state.auth.userProfileInfo,

        })
    },
    methods: {
      
        sendToMarket() {
            this.$router.push('/market')
        },

        changePassword() {

            let apiData = {
                old: this.oldPassword,
                new: this.newPassword
            }

            this.$store.commit("auth/startLoader");
            http.patch(api.changePassword, apiData)
                .then(response => {
                     this.$store.commit("auth/stopLoader");
                    this.$helpers.log(response, 'Change password');
                    this.$toast.success({
                        title: this.tran.success,
                        icon: require("../../assets/images/successicon.png"),
                        timeOut: 5000,
                        message: "Password changed successfully",
                        position: "top center"
                    });

                    this.$router.push('/profile')
                })
                .catch(error => {
                    this.$store.commit("auth/stopLoader");
                    this.$helpers.log(error.response);
                    if (error.response !== undefined) {
                        this.$toast.warn({
                            title: this.tran.sorry,
                            icon: require("../../assets/images/cautionicon.png"),
                            timeOut: 5000,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    }

                });
        },
        showInputText() {

            this.hideInput = "text";
            this.showEye = false;
            this.hideEye = true;

        },
   
        hideInputText() {

            this.hideInput = "password";
            this.showEye = true;
            this.hideEye = false;

        },

        focusOnInput(e) {
            e.target.parentNode.querySelector('.form-input').focus();
        },

        checkEnterButton() {
            if (event.keyCode === 13) {
                this.changePassword();
            }
        },
    },

    mounted() {
        this.tran = trans;

      
    },
    beforeCreate() {
        if (this.token !== null) {

            this.$store.dispatch('auth/getUserProfile');

        }
    }
};
</script>

<style>
</style>
