<template>
    <ChangePassword/>
</template>

<script>
import ChangePassword from '../../components/auth/ChangePassword'
export default {

    components: {
        ChangePassword
    }
}
</script>

<style>

</style>
